import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import { MainProtectedRoute } from './mainProtectedRoute'
import { UserProtectedRoutes } from './userProtectedRoutes'

const Main = lazy(() => import('../../pages/main'))
const Registration = lazy(() => import('../../pages/registration'))
const Restore = lazy(() => import('../../pages/restore'))
const Login = lazy(() => import('../../pages/login'))

export const UserRoutes = () => {
    return (
        <Route element={<UserProtectedRoutes />}>
            <Route element={<MainProtectedRoute />}>
                <Route
                    path='/'
                    element={
                        <Suspense>
                            <Main />
                        </Suspense>
                    }
                    id={'/'}
                />
            </Route>
            <Route
                path='/register'
                element={
                    <Suspense>
                        <Registration />
                    </Suspense>
                }
                id={'/register'}
            />
            <Route
                path='login'
                element={
                    <Suspense>
                        <Login />
                    </Suspense>
                }
                id={'login'}
            />
            <Route
                path='/restore'
                element={
                    <Suspense>
                        <Restore />
                    </Suspense>
                }
                id={'restore'}
            />
        </Route>
    )
}
