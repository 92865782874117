import { memo, ReactElement } from 'react'
import { useAuth } from 'contexts/auth'

export const WithAuth = memo((props: { children: ReactElement }) => {
    const { isAuthorized, isAuthLoading } = useAuth()

    if (isAuthLoading) {
        return null
    }

    if (!isAuthorized) {
        return null
    }

    return props.children
})
