import { ACCESS_TOKEN_KEY } from '../consts/general'

export function formatNumber(number: number) {
    if (Math.abs(number) < 1.0) {
        const e = parseInt(number.toString().split('e-')[1])

        if (e) {
            const negative = number < 0

            if (negative) {
                number *= -1
            }

            const numberPow = number * Math.pow(10, e - 1)
            let strResult = '0.' + new Array(e).join('0') + numberPow.toString().substring(2)

            if (negative) {
                strResult = '-' + strResult
            }

            return strResult
        }
    }
    return number
}

export const checkCurrencyInputValue = (value: string): boolean => value === '' || /^\d+(\.\d{0,9})?$/.test(value)

export const getQueryParamValue = (url: string, paramName: string) => {
    const urlObject = new URL(url)
    const queryParams = urlObject.searchParams

    return queryParams.get(paramName)
}

export function setAccessToken(token: string) {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export function removeAccessToken() {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY)
}

export function getAccessToken() {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY)
}

export function removeQueryParameters() {
    const currentURL = window.location.href

    if (currentURL.indexOf('?') !== -1) {
        const newURL = currentURL.split('?')[0]
        window.location.href = newURL
    }
}

export function replaceLocation(path: string) {
    if (window.location.pathname !== path) {
        document.location.replace(path)
    }
}

export const getPhoneNumberFromStorage = (): string | null => {
    return sessionStorage.getItem('phoneNumber')
}

export const savePhoneNumberToStorage = (phoneNumber: string) => {
    sessionStorage.setItem('phoneNumber', phoneNumber)
}

export const getPhoneCountryCodeFromStorage = (): string | null => {
    return sessionStorage.getItem('phoneCountryCode')
}

export const savePhoneCountryCodeToStorage = (phoneCountryCode: string) => {
    sessionStorage.setItem('phoneCountryCode', phoneCountryCode)
}

export const downloadFile = (data: BlobPart, fileName?: string) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName || 'file'}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
}
