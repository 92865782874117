import { useAuth } from 'contexts/auth'
import { Navigate, Outlet } from 'react-router-dom'

export const MainProtectedRoute = () => {
    const { user } = useAuth()

    if (!user) {
        return null
    }

    if (user && user.status !== 'active') {
        return <Navigate to={'/register'} />
    }
    return <Outlet />
}
