import jwt_decode from 'jwt-decode'
import React, { memo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessToken } from '../../utils/general'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

export const DashboardProtectedRoutes = memo(() => {
    const token = getAccessToken()

    if (token) {
        const decoded = jwt_decode<{ role?: string; exp?: number }>(token)
        const isTokenValid = decoded?.role === 'ADMIN' && Date.now() < (decoded?.exp ?? 0) * 1000
        return isTokenValid ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Outlet />
            </LocalizationProvider>
        ) : (
            <Navigate to='/401' />
        )
    }

    return <Navigate to='/401' />
})
