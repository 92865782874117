import { DashboardNavBar } from 'components/DashboardNavBar'
import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import { DashboardProtectedRoutes } from './dashboardProtectedRoute'

const WelcomePage = React.lazy(() => import('../../pages/dashboard'))
const BonusExchanges = React.lazy(() => import('../../pages/dashboard/bonus-exchanges'))
const StablecoinExchanges = React.lazy(() => import('../../pages/dashboard/stablecoin-exchanges'))
const UsersExchangePermissions = React.lazy(() => import('../../pages/dashboard/users-exchange-permissions'))

const routes = [
    { path: '/dashboard', name: 'Home', page: <WelcomePage /> },
    { path: '/dashboard/bonus-exchanges', name: 'Bonus exchanges', page: <BonusExchanges /> },
    { path: '/dashboard/stablecoin-exchanges', name: 'Token exchanges', page: <StablecoinExchanges /> },
    {
        path: '/dashboard/users-exchange-permissions',
        name: 'Users exchange permissions',
        page: <UsersExchangePermissions />,
    },
]

export const DashboardRoutes = () => {
    return (
        <Route element={<DashboardProtectedRoutes />}>
            <Route element={<DashboardNavBar items={routes} />}>
                {routes.map(i => (
                    <Route path={i.path} element={<Suspense>{i.page}</Suspense>} key={i.path} />
                ))}
            </Route>
        </Route>
    )
}
