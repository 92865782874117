import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { replaceLocation } from '../utils/general'

function generateBearerToken(token: string) {
    return `Bearer ${token}`
}

class API {
    private axiosInstance: AxiosInstance

    constructor() {
        const accessToken = sessionStorage.getItem('access_token')

        const headers = accessToken
            ? {
                  Authorization: generateBearerToken(accessToken),
              }
            : {}

        this.axiosInstance = axios.create({
            baseURL: '/api/v1',
            headers,
        })

        this.axiosInstance.interceptors.response.use(
            response => {
                // Do something with a successful response
                return response
            },
            error => {
                if (error.response.status === 401) {
                    // Handle the 401 error here (e.g., redirect to a login page)
                    console.log('Unauthorized error (401)')
                    replaceLocation('/401')
                }
                return Promise.reject(error.response?.data)
            }
        )
    }

    setAccessToken(accessToken: string) {
        this.axiosInstance.defaults.headers['Authorization'] = generateBearerToken(accessToken)
    }

    removeAccessToken() {
        delete this.axiosInstance.defaults.headers['Authorization']
    }

    setProductKey(key: string) {
        this.axiosInstance.defaults.headers.common['X-Product-Key'] = key
    }

    async get<ResponseType>(endpoint: string) {
        return this.axiosInstance.get(endpoint).then(({ data }) => data as ResponseType)
    }

    async post<RequestType = unknown, ResponseType = RequestType>(
        endpoint: string,
        body: RequestType,
        config?: AxiosRequestConfig
    ) {
        return this.axiosInstance.post(endpoint, body, config).then(({ data }) => data as ResponseType)
    }

    async put<RequestType = unknown, ResponseType = RequestType>(endpoint: string, body: RequestType) {
        return this.axiosInstance.put(endpoint, body).then(({ data }) => data as ResponseType)
    }

    async patch<RequestType = unknown, ResponseType = RequestType>(endpoint: string, body: RequestType) {
        return this.axiosInstance.patch(endpoint, body).then(({ data }) => data as ResponseType)
    }

    async delete<ResponseType>(endpoint: string) {
        return this.axiosInstance.delete(endpoint).then(({ data }) => data as ResponseType)
    }
}

export const apiClient = new API()
