import '@fontsource/plus-jakarta-sans/200.css'
import '@fontsource/plus-jakarta-sans/300.css'
import '@fontsource/plus-jakarta-sans/400.css'
import '@fontsource/plus-jakarta-sans/500.css'
import '@fontsource/plus-jakarta-sans/600.css'
import '@fontsource/plus-jakarta-sans/700.css'
import '@fontsource/plus-jakarta-sans/800.css'
import GlobalStyles from '@mui/material/GlobalStyles'

export const GlobalStyle = () => {
    return (
        <GlobalStyles
            styles={theme => ({
                body: {
                    margin: '0',
                    padding: '0',
                    background: '#141719',
                    fontFamily: 'Plus Jakarta Sans,serif',
                },
                '& .wallet-adapter-modal': {
                    zIndex: theme.zIndex.drawer + 1,
                },
            })}
        />
    )
}
