import { Switch } from '@mui/material'
import type { SnowflakeSafeWalletAdapter } from '@snowflake-so/wallet-adapter-snowflake'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import type { TrustWalletAdapter } from '@solana/wallet-adapter-trust'
import { Cluster, clusterApiUrl } from '@solana/web3.js'
import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react'
import { RpcConnector } from '../routes/userRoutes/rpcConnector'
import { StyledSwitcherBox } from '../styles'

const SolanaRpcConnectionContext = createContext<boolean | null>(null)
const SolanaRpsConnectionSetterContext = createContext<Dispatch<SetStateAction<boolean>> | null>(null)

const mainNetUrls = [
    process.env['NX_SOLANA_MAIN_NET_URL'] ?? '',
    process.env['NX_MAIN_NET_URL'] ?? '',
    process.env['NX_HELIUS_MAIN_NET_URL'] ?? '',
]
const network = (process.env['NX_SOLANA_CLUSTER'] ?? 'devnet') as Cluster

type Props = {
    wallets: (TrustWalletAdapter | SnowflakeSafeWalletAdapter)[]
}

export const SolanaRpcConnectionProvider = (props: PropsWithChildren<Props>) => {
    const { children, wallets } = props

    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const [productionMode, setProductionMode] = useState<boolean>(true)
    const [isConnected, setIsConnected] = useState(false)
    const [mainNetUrlIndex, setMainNetUrlIndex] = useState(0)

    const handleChangeMode = useCallback(() => {
        setProductionMode(mode => !mode)
    }, [])

    const handleConnectionFail = useCallback(() => {
        // sets next index from 0 to mainNetUrls.length - 1
        setMainNetUrlIndex(index => (index + 1) % mainNetUrls.length)
    }, [])

    const endpoint = useMemo(() => {
        if (productionMode) {
            return mainNetUrls[mainNetUrlIndex]
        }

        return clusterApiUrl(network)
    }, [mainNetUrlIndex, productionMode])

    return (
        <ConnectionProvider endpoint={endpoint}>
            <SolanaRpcConnectionContext.Provider value={isConnected}>
                <SolanaRpsConnectionSetterContext.Provider value={setIsConnected}>
                    <WalletProvider wallets={wallets} autoConnect>
                        <WalletModalProvider>
                            <RpcConnector onConnectionFail={handleConnectionFail} />
                            <StyledSwitcherBox>
                                <Switch checked={productionMode} onChange={handleChangeMode} />
                            </StyledSwitcherBox>
                            {children}
                        </WalletModalProvider>
                    </WalletProvider>
                </SolanaRpsConnectionSetterContext.Provider>
            </SolanaRpcConnectionContext.Provider>
        </ConnectionProvider>
    )
}

export const useSolanaRpcConnection = () => {
    const context = useContext(SolanaRpcConnectionContext)
    if (context === null) {
        throw new Error('useSolanaRpcConnection must be used within a SolanaRpcConnectionProvider')
    }
    return context
}

export const useSolanaRpcConnectionSetter = () => {
    const context = useContext(SolanaRpsConnectionSetterContext)
    if (!context) {
        throw new Error('useSetSolanaRpcConnection must be used within a SolanaRpcConnectionProvider')
    }
    return context
}
