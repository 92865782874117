import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'api'
import { GlobalStyle } from '../global-styles'
import { ThemeProvider } from '@mui/material/styles'
import { darkTheme } from './theme'
import { AppRouter } from './routes'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { SnackbarUtilsConfigurator } from 'components/SnackbarUtilsConfigurator'
import { useMemo } from 'react'
require('@solana/wallet-adapter-react-ui/styles.css')

export function App() {
    const snackbarProviderStyles: SnackbarProviderProps['style'] = useMemo(() => {
        return { whiteSpace: 'pre-line' }
    }, [])

    return (
        <ThemeProvider theme={darkTheme}>
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider preventDuplicate={true} autoHideDuration={3000} style={snackbarProviderStyles}>
                    <SnackbarUtilsConfigurator />
                    <AppRouter />
                    <ReactQueryDevtools initialIsOpen={false} />
                    <GlobalStyle />
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeProvider>
    )
}
