import { useConnection } from '@solana/wallet-adapter-react'
import { useSolanaRpcConnectionSetter } from 'contexts/SolanaRpcConnection'
import { memo, useEffect } from 'react'

type Props = {
    onConnectionFail: () => void
}

export const RpcConnector = memo((props: Props) => {
    const { onConnectionFail } = props
    const { connection } = useConnection()
    const setSolanaRpcConnection = useSolanaRpcConnectionSetter()

    useEffect(() => {
        connection
            .getVersion()
            .then(() => {
                setSolanaRpcConnection(true)
            })
            .catch(() => {
                onConnectionFail()
            })
    }, [connection, onConnectionFail, setSolanaRpcConnection])

    return null
})
