import * as ReactDOM from 'react-dom/client'
import { App } from './app/app'
import * as Sentry from '@sentry/react'
import { httpClientIntegration } from '@sentry/integrations'

if (process.env['SERVER_ENV'] !== 'development') {
    Sentry.init({
        dsn: process.env['SENTRY_REPLAYS_DSN'],
        integrations: [
            Sentry.replayIntegration({
                networkDetailAllowUrls: [/.*/],
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.browserTracingIntegration(),
            httpClientIntegration({ failedRequestStatusCodes: [[300, 599]] }),
        ],
        tracePropagationTargets: [/.*/],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.3,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env['SERVER_ENV'],
    })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
