import { SnowflakeSafeWalletAdapter } from '@snowflake-so/wallet-adapter-snowflake'
import { TrustWalletAdapter } from '@solana/wallet-adapter-trust'
import { SolanaRpcConnectionProvider } from 'contexts/SolanaRpcConnection'
import React, { memo, useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const AdminProtectedRoutes = memo(() => {
    const wallets = useMemo(() => [new TrustWalletAdapter(), new SnowflakeSafeWalletAdapter()], [])

    return (
        <SolanaRpcConnectionProvider wallets={wallets} key={'adminConnectionProvider'}>
            {process.env['NX_ALLOW_ACCESS_TO_ADMIN'] === 'true' ? <Outlet /> : <Navigate to='/401' />}
        </SolanaRpcConnectionProvider>
    )
})
