import { Container } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { StyledNavLink } from 'components/DashboardNavBar/styles'
import { memo, useMemo } from 'react'
import { Outlet } from 'react-router-dom'

type Item = {
    path: string
    name: string
}

type Props = {
    items: Item[]
}
export const DashboardNavBar = memo((props: Props) => {
    const Links = useMemo(
        () =>
            props.items.map(i => (
                <StyledNavLink to={i.path} key={'nav' + i.path} end>
                    {i.name.toUpperCase()}
                </StyledNavLink>
            )),
        [props.items]
    )

    return (
        <>
            <AppBar position={'static'}>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters sx={{ gap: '10px' }}>
                        {Links}
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
        </>
    )
})
