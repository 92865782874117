import { createTheme } from '@mui/material/styles'
import { CSSProperties } from '@mui/material/styles/createTypography'
// import {CSSProperties} from "@mui/styled-engine";

export const darkTheme = createTheme({
    typography: {
        fontFamily: 'Plus Jakarta San, sans-serif',
        allVariants: {
            color: 'white',
            lineHeight: '140%',
            fontStyle: 'normal',
        },
        h1: {
            fontSize: '48px',
            fontWeight: '700',
            '@media (max-width:600px)': {
                fontSize: '32px',
                lineHeight: '125%',
            },
        },
        h2: {
            fontSize: '36px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                fontSize: '28px',
                lineHeight: '125%',
            },
        },

        h3: {
            fontSize: '24px',
            fontWeight: '700',
            '@media (max-width:600px)': {
                fontSize: '22px',
                lineHeight: '125%',
            },
        },

        button: {
            fontSize: '24px',
            fontWeight: '700',
            '@media (max-width:600px)': {
                fontSize: '22px',
                lineHeight: '125%',
            },
        },

        body1: {
            fontSize: '32px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                fontSize: '28px',
                lineHeight: '125%',
            },
        },
        body2: {
            fontSize: '24px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                fontSize: '22px',
                lineHeight: '125%',
            },
        },

        callout1: {
            fontSize: '16px',
            fontWeight: '700',
            '@media (max-width:600px)': {
                fontSize: '15px',
                lineHeight: '125%',
            },
        },
        callout2: {
            fontSize: '16px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                fontSize: '15px',
                lineHeight: '125%',
            },
        },
        footnote1: {
            fontSize: '14px',
            fontWeight: '700',
            '@media (max-width:600px)': {
                lineHeight: '125%',
            },
        },
        footnote2: {
            fontSize: '14px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                lineHeight: '125%',
            },
        },
        caption: {
            fontSize: '12px',
            fontWeight: '400',
            '@media (max-width:600px)': {
                lineHeight: '125%',
            },
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#141719',
        },
    },
})

declare module '@mui/material/styles' {
    interface TypographyVariants {
        h4?: never
        h5?: never
        h6?: never
        subtitle1?: never
        subtitle2?: never
        overline?: never
        callout1: CSSProperties
        callout2: CSSProperties
        footnote1: CSSProperties
        footnote2: CSSProperties
    }

    interface TypographyVariantsOptions {
        h4?: never
        h5?: never
        h6?: never
        subtitle1?: never
        subtitle2?: never
        overline?: never
        callout1?: CSSProperties
        callout2?: CSSProperties
        footnote1?: CSSProperties
        footnote2?: CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h4: false
        h5: false
        h6: false
        subtitle1: false
        subtitle2: false
        overline: false
        callout1: true
        callout2: true
        footnote1: true
        footnote2: true
    }
}
