import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

const AccessDenied = lazy(() => import('../../pages/401'))
const DashboardLogin = lazy(() => import('../../pages/dashboard/login'))

export const PublicRoutes = () => {
    return (
        <>
            <Route
                path={'/dashboard/login'}
                element={
                    <Suspense>
                        <DashboardLogin />
                    </Suspense>
                }
                id={'/dashboard/login'}
            />
            <Route
                path='/401'
                element={
                    <Suspense>
                        <AccessDenied />
                    </Suspense>
                }
                id={'/401'}
            />
        </>
    )
}
