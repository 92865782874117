import { createContext, useContext, useEffect, useState } from 'react'
import { User } from 'types'
import {
    getAccessToken,
    getQueryParamValue,
    removeQueryParameters,
    replaceLocation,
    setAccessToken,
} from '../utils/general'
import { getCurrentUserInfo } from 'services/users'

type Auth = {
    isAuthorized: boolean
    user?: User
    isAuthLoading: boolean
}

const AuthContext = createContext({} as Auth)

export function AuthProvider({ children }: { children: JSX.Element }) {
    const auth = useAuthProvider()
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth() {
    return useContext(AuthContext)
}

function useAuthProvider(): Auth {
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const [user, setUser] = useState<User>()

    useEffect(() => {
        setIsAuthLoading(true)
        const access_token = getQueryParamValue(window.location.href, 'access_token')

        if (access_token) {
            setAccessToken(access_token)
            removeQueryParameters()
        } else if (getAccessToken()) {
            getCurrentUserInfo()
                .then(result => {
                    if (!result || !result.isSuccessful || !result.value) {
                        return
                    }

                    const {
                        value: { status, userData },
                    } = result

                    setUser(result.value)

                    if (status === 'inactive') {
                        replaceLocation('/401')
                    } else if (status === 'active' && window.location.pathname !== '/restore') {
                        replaceLocation('/')
                    } else if (window.location.pathname === '/login' || !userData) {
                        replaceLocation('/register')
                    }
                })
                .catch(e => {
                    replaceLocation('/401')
                    setIsAuthLoading(false)
                    return
                })
        } else {
            replaceLocation('/401')
            setIsAuthLoading(false)
            return
        }

        setIsAuthLoading(false)
        setIsAuthorized(true)
    }, [])

    return {
        isAuthorized,
        user,
        isAuthLoading,
    }
}
