export const colors = {
    light: '#FFF',
    dark: '#0D0F10',
    accent: '#7389AB',
    disabled: '#A5A5A5',
    primaryColor: '#F06321',
    darkDefault: '#141719',
    darkHover: '#202426',
    error: '#E51818',
    darkActive: '#090909',
    primaryColorActive: '#EB4A00',
    primaryHover: '#EC743C',
    success: '#94EE00',
    walletActive: '#2B1B64',
    walletDefault: '#311F71',
    walletHover: '#3D2C78',
}

export const gradients = {
    primary: `linear-gradient(90deg, #FF5100 0%, ${colors.primaryColor} 100%)`,
    primaryHover: `linear-gradient(90deg, #FA6824 0%, ${colors.primaryHover} 100%)`,
    primaryActive: 'linear-gradient(90deg, #FF2E00 0%, #FF5F15 100%)',
    disabled: `linear-gradient(90deg, #686868 0%, ${colors.disabled} 100%)`,
}
