import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import { AdminProtectedRoutes } from './adminProtectedRoutes'

const Admin = React.lazy(() => import('../../pages/admin'))

export const AdminRoutes = () => {
    return (
        <Route element={<AdminProtectedRoutes />}>
            <Route
                path='admin'
                element={
                    <Suspense fallback={'Loading'}>
                        <Admin />
                    </Suspense>
                }
                id={'admin'}
            />
        </Route>
    )
}
