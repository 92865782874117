import React, { lazy, memo } from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { AdminRoutes } from './adminRoutes'
import { DashboardRoutes } from './dashboardRoutes'
import { PublicRoutes } from './publicRoutes'
import { UserRoutes } from './userRoutes'

const NoMatch = lazy(() => import('../pages/no-match'))

export const AppRouter = memo(() => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                {DashboardRoutes()}
                {UserRoutes()}
                {AdminRoutes()}
                {PublicRoutes()}
                <Route path='/*' element={<NoMatch />} />
            </>
        )
    )

    return <RouterProvider router={router} />
})
