import { TrustWalletAdapter } from '@solana/wallet-adapter-trust'
import { AuthProvider } from 'contexts/auth'
import { SolanaRpcConnectionProvider } from 'contexts/SolanaRpcConnection'
import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { WithAuth } from '../../hoc/withAuth'

const wallets = [new TrustWalletAdapter()]

export const UserProtectedRoutes = memo(() => {
    return (
        <AuthProvider>
            <WithAuth>
                <SolanaRpcConnectionProvider wallets={wallets}>
                    <Outlet />
                </SolanaRpcConnectionProvider>
            </WithAuth>
        </AuthProvider>
    )
})
