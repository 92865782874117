import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { colors } from '../../../theme'

export const StyledNavLink = styled(NavLink)(() => ({
    color: colors.light,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '140%',
    textDecoration: 'none',
    '&.active': {
        fontWeight: 700,
        color: colors.primaryColor,
    },
}))
